/**
 * @desc WIFI资源库
 */
import React from "react";
import Search from "./search";
import { toJS } from "mobx";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";

const LittlePagtion = Loader.loadBusinessComponent("BaseLibComponents", "LittlePagtion");
const NoData = Loader.loadBaseComponent("NoData");
const Wrapper = Loader.loadBusinessComponent("BaseLibComponents", "Wrapper");
const WifiCard = Loader.loadBusinessComponent("Card", "WifiCard");
const InfiniteScrollLayout = Loader.loadBaseComponent("ReactVirtualized", "InfiniteScrollLayout");
const ModalComponent = Loader.loadBaseComponent("ModalComponent");
const SelectMap = Loader.loadBusinessComponent("MapComponent", "MapPointLableView");
const IconFont = Loader.loadBaseComponent("IconFont");
const Loading = Loader.Loading;

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider("wifi", "tab")
@Decorator.businessObserver({ wifi: ["searchData"] })
class wifiLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.urlParams = Utils.queryFormat(props.location.search);
    this.state = {
      list: [],
      loading: true,
      isShowAdressModal: false,
      listKey: Math.random(),
      //inputValue: '', // 搜索框值（用于页面回显以及重置后清空，也可以使用key更新input的文本）
      searchKey: Math.random(),
      wifiItem: {}
    };
  }
  componentWillMount() {
    const { searchData } = this.props.wifi;
    let id = this.urlParams.id;
    if (id) {
      LM_DB.get("parameter", id).then(result => {
        if (result) {
          let searchData = result.searchData || {};
          // this.setState({
          //   inputValue: searchData.keywords
          // })
          this.mergeSearchData(searchData);
        } else {
          this.reset();
        }
      });
    } else {
      // 没有id时
      this.mergeSearchData(Utils.getTimerArea(searchData.timerTabsActive));
    }
  }

  /**
   * @desc 查询
   * @param {Boolean} isLoadMore 是否加载更多
   */
  search = (isLoadMore = false) => {
    const { offset, limit, endTime, startTime, cids, keywords } = this.props.wifi.searchData;
    let options = {
      limit,
      offset,
      endTime,
      startTime,
      cids: !!cids.length ? cids.map(v => v.cid || v.id) : undefined,
      keywords
    };
    !isLoadMore &&
      this.setState(
        {
          loading: true
        },
        () => {
          Service.wifi
            .queryWifiDatas(options)
            .then(result => {
              let list = [];
              let dataList = result.data.list || [];
              if (isLoadMore) {
                list = this.state.list;
              }
              list = list.concat(dataList);
              this.setState({
                list,
                loading: false,
                total: result.data.total,
                listKey: isLoadMore ? this.state.listKey : Math.random()
              });
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        }
      );
  };

  /**
   * @desc 修改查询条件
   * @param {Object} options 查询条件
   * @param {Boolean} needSearch 是否进行查询, 默认查询
   * @param {Boolean} needReplace 是否存储indexDB
   */
  mergeSearchData = (options, needSearch = true, needReplace = false) => {
    if (!options.offset) {
      options.offset = 0; // 条件修改后重置偏移量
    }
    return this.props.wifi.mergeSearchData(options).then(() => {
      if (needSearch) {
        this.search();
        if (needReplace) {
          let id = Utils.uuid();
          let indexDBObj = {
            id,
            searchData: toJS(this.props.wifi.searchData)
          };
          LM_DB.add("parameter", indexDBObj).then(() => {
            this.props.tab.goPage({
              moduleName: "wifiLibrary",
              location: this.props.location,
              isUpdate: true,
              data: { id },
              action: "replace"
            });
          });
        }
      }
    });
  };

  /**
   * @desc 刷新
   */
  Refresh = () => {
    // 自定义时间时，时间不变
    let timerTabsActive = this.props.wifi.searchData.timerTabsActive;
    timerTabsActive === 2 ? this.mergeSearchData({}) : this.mergeSearchData(Utils.getTimerArea(timerTabsActive));
  };

  /**
   * @desc 重置-删除url参数
   */
  reset = () => {
    const { wifi } = this.props;
    wifi.initData();
    wifi.mergeSearchData(Utils.getTimerArea(wifi.searchData.timerTabsActive)).then(() => {
      this.setState({
        searchKey: Math.random()
      });
      this.search();
      this.props.tab.goPage({
        moduleName: "wifiLibrary",
        location: this.props.location,
        isUpdate: true,
        data: {},
        action: "replace"
      });
    });
  };

  /**
   * @desc 加载更多
   */
  loadMore = () => {
    if (this.state.list.length >= this.state.total) {
      return;
    }
    let { offset, limit } = this.props.wifi.searchData;
    let searchDataObj = {
      offset: offset + limit
    };
    this.mergeSearchData(searchDataObj, false).then(res => {
      this.search(true);
    });
  };

  /**
   * @desc wifi点位信息
   */
  locationDetail = item => {
    item.deviceType = Dict.map.wifi.value;
    this.setState({
      isShowAdressModal: true,
      wifiItem: item
    });
  };

  /**
   * @desc 关闭弹框
   */
  handleCancel = () => {
    this.setState({
      isShowAdressModal: false,
      wifiItem: {}
    });
  };

  /**
   * @desc 搜索框值改变时
   * @param {Object} e 合成事件对象
   */
  // inputValueChange = e => {
  //   this.setState({
  //     inputValue: e.target.value
  //   })
  // }

  render() {
    const { list, total = 0, loading, isShowAdressModal, wifiItem, listKey, inputValue, searchKey } = this.state;
    const { searchData } = this.props.wifi;
    return (
      <Loading loading={loading} wrapperClassName="baselib-spining">
        <Wrapper className="wifi-wrapper" title="wifi资源库" reset={this.reset}>
          <Search
            mergeSearchData={(searchData, needSearch) => this.mergeSearchData(searchData, needSearch, true)}
            searchData={searchData}
            // inputValue={inputValue}
            // inputValueChange={this.inputValueChange}
            key={searchKey}
          />
          <div className="baselib-list-wrapper">
            {!!list.length ? (
              <InfiniteScrollLayout
                key={listKey}
                itemWidth={298}
                itemHeight={300}
                pdWidth={60}
                data={list}
                hasBackTop={true}
                loadMore={this.loadMore}
                hasLoadMore={!loading}
                renderItem={v => <WifiCard item={v} locationDetail={this.locationDetail} />}
              />
            ) : (
              <NoData />
            )}
          </div>
          <div className="header-little-pagtion">
            <LittlePagtion onChange={this.mergeSearchData} searchData={searchData} Refresh={this.Refresh} total={total} />
          </div>
        </Wrapper>
        <ModalComponent
          title="位置详情"
          width={1004}
          height={645}
          visible={isShowAdressModal}
          footer={null}
          otherModalFooter={true}
          className="wifi-address-modal"
          onCancel={this.handleCancel}
        >
          <div className="detail-title">
            <IconFont type="icon-S_Bar_Add" />
            <span>{wifiItem.captureDeviceName}</span>
            地址: {wifiItem.address}
          </div>
          <SelectMap point={wifiItem} showSearch={false} />
        </ModalComponent>
      </Loading>
    );
  }
}

export default wifiLibrary;
