import React from 'react'

const Timer = Loader.loadBusinessComponent('BaseLibComponents', 'Timer')
const WifiDevicesSelect = Loader.loadBusinessComponent('BaseLibComponents', 'WifiDevicesSelect')
// const ColligateSearch = Loader.loadBusinessComponent('ColligateSearch')
const SearchInput = Loader.loadComponent('SearchInput')

const IconFont = Loader.loadBaseComponent('IconFont')
export default class Search extends React.Component {
  /**
   * @desc 编辑查询条件
   */
  change = (options={}, needSearch = true) => {
    this.props.mergeSearchData(options, needSearch)
  }

  render(){
    const { searchData = {}, inputValue, inputValueChange } = this.props
    return (
      <div className='baselib-search-wrapper'>
        <div className='kw-search'>
          <SearchInput 
            width='100%'
            placeholder='请输入关键字搜索'
            // onSearch={keywords => this.change({ keywords })}
            value={searchData.keywords}
            onChange={keywords => this.change({ keywords })}
            // enterButton={<IconFont type='icon-S_Edit_Search' />}
            className='wifi-search'
          />
        </div>
        <div className='small-title'>资源筛选 :</div>
        <Timer 
          value={searchData.timerTabsActive}
          change={this.change}
          onOk={this.change}
          startTime={searchData.startTime}
          endTime={searchData.endTime}
          needSearch={true}
        />
        <WifiDevicesSelect 
          onChange={this.change}
          selectList={searchData.cids}
        />
      </div>
    )
  }
}